<template>
	<div>
		<hr />
		<!-- eslint-disable vue/no-v-html -->
		<p
			data-qa="messageFooter"
			v-html="t('messageFooter')"
		/>
		<!-- eslint-enable -->
	</div>
</template>

<script lang="ts" setup>
import {useI18n} from "vue-i18n";

const { t } = useI18n()
</script>

<style lang="scss" scoped>
hr {
	margin: 3rem -2rem 1rem;
	border-top: 1px solid lightgrey;
}
</style>
