<template>
    <div>
        <p>
            Dear Sir or Madam,
        </p>

        <p>
            a new supplement to your product {{ templateVars.productOrderId }} on {{ templateVars.mergedReportData }} is now
            available.
        </p>

        <p>
            <strong>The changes concern:</strong>
        <ul class="posteingang-inbox__message-details-messages__message__change-list">
            <li v-for="reason in templateVars.supplementReasonIdList" :key="reason">
                {{ t("supplementReason." + reason) }}
            </li>
        </ul>
        </p>

        <p>
            Please click
            <crefo-message-link :msg-link="templateVars.transactionLink" :msg-text="'here'" /> to access the information.
        </p>

        <p>With kind regards</p>

        <p>{{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'
import {useI18n} from "vue-i18n";

const { t } = useI18n()

defineProps<{
    templateVars: {
        transactionLink: string,
        mergedReportData: string,
        productOrderId: string,
        supplementReasonIdList: Array<string>,
        vcName:string,
    }
}>()
</script>
