<template>
    <div>
        <p>
            Mesdames et Messieurs,
        </p>

        <p>
            pour votre commande de renseignement {{ templateVars.productOrderId }} concernant l'entreprise {{ templateVars.mergedReportData }} aucun autre supplément ne sera livré.
        </p>

        <p>
            <strong>Raison de la fin:</strong>
            <ul class="posteingang-inbox__message-details-messages__message__change-list">
                <li
                    v-for="reason in templateVars.signalReasonIdList"
                    :key="reason"
                >
                    {{ t("signalReason." + reason) }}
                </li>
            </ul>
        </p>

        <p>Cordialement</p>

        <p>Votre {{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import {useI18n} from "vue-i18n";

const { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        productOrderId: string,
        signalReasonIdList: Array<string>,
        transactionLink: string,
        vcName: string
    }
}>()
</script>
