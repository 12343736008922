<template>
    <div>
        <p>
            Dear Sir or Madam,
        </p>

        <p>
            no additional supplements will be delivered for your product order {{ templateVars.productOrderId }} on the company {{ templateVars.mergedReportData }}.
        </p>

        <p>
            <strong>Reason for termination:</strong>
            <ul class="posteingang-inbox__message-details-messages__message__change-list">
                <li
                    v-for="reason in templateVars.signalReasonIdList"
                    :key="reason"
                >
                    {{ t("signalReason." + reason) }}
                </li>
            </ul>
        </p>

        <p>With kind regards</p>

        <p>{{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import {useI18n} from "vue-i18n";

const { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        productOrderId: string,
        signalReasonIdList: Array<string>,
        transactionLink: string,
        vcName: string
    }
}>()
</script>
