import { Base } from '@/models/Messages/Base'
import { Nachricht } from '@/types/messageBox/messages'
import countryHelper from '@/helper/country'
import { Locale } from 'vue-i18n'

const AVAILABLE_TEMPLATES = [
	{ templateId: 'A001', template: 'actualisation' },
	{ templateId: 'LLFAR-1', template: 'actualisation' },
	{ templateId: 'A002', template: 'order_report_or_lack_of_report' },
	{ templateId: 'LLFAR-2', template: 'order_report_or_lack_of_report' },
	{ templateId: 'A003', template: 'order_status_reply' },
	{ templateId: 'LLFAR-3', template: 'order_status_reply' },
	{ templateId: 'A004', template: 'supplement_regular' },
	{ templateId: 'LLFAR-4', template: 'supplement_regular' },
]

export type PostalAddress = {
	city?: string,
	street?: string,
	zipCode?: string,
	country?: string,
	district?: string,
	houseNumber?: number,
	houseNumberSuffix?: string,
	postOfficeBoxNumber?: string,
	type?: string
}
type Attributes = {
	businessName?: string,
	businessId?: string,
	productOrderId?: string,
	postalAddress?: PostalAddress,
	deliveryTypeId?: string,
	referenceNumber?: string,
	signalReasonIdList?: Array<string>,
	statusReplyReasonId?: string,
	supplementReasonIdList?: Array<string>,
	transactionId?: string,
}
export class Auskunft extends Base {
	attributes: Attributes
	headlineText: string
	// type discriminator (same attribute name, different type and (constant) content):
	nachrichtType: "FIRMENAUSKUENFTE" = "FIRMENAUSKUENFTE"

	constructor(data: Nachricht) {
		super(data)
		this.attributes = {
			businessName: this.payload.displayName,
			businessId: this.payload.businessId,
			productOrderId: this.payload.orderId,
			postalAddress: this.getPostalAddress(this.payload),
			deliveryTypeId: this.payload.deliveryTypeId,
			referenceNumber: this.payload.referenceNumber,
			signalReasonIdList: this.payload.signalReasonIdList,
			statusReplyReasonId: this.payload.statusReplyReasonId,
			supplementReasonIdList: this.payload.supplementReasonIdList,
			transactionId: this.getTransactionId(this.payload),
		} as Attributes
		this.msgTemplate = this.getTemplate(data.templateId, AVAILABLE_TEMPLATES)
		this.headlineText = this.getHeadlineText(this.payload)
	}

	getPostalAddress(payload: { postalAdress?: PostalAddress}) {
		return {
			city: payload.postalAdress?.city,
			street: payload.postalAdress?.street,
			zipCode: payload.postalAdress?.zipCode,
			country: payload.postalAdress?.country,
			district: payload.postalAdress?.district,
			houseNumber: payload.postalAdress?.houseNumber,
			houseNumberSuffix: payload.postalAdress?.houseNumberSuffix,
			postOfficeBoxNumber: payload.postalAdress?.postOfficeBoxNumber,
			type: payload.postalAdress?.type,
		} as PostalAddress
	}
}

export function getMergedReportData(attributes:  Attributes | undefined, locale: Locale) {
	if (attributes === undefined) {
		return ''
	}
	return [
		attributes?.businessName,
		attributes?.postalAddress?.zipCode,
		attributes?.postalAddress?.city,
		countryHelper.getCountryName(attributes?.postalAddress?.country, locale)
	].filter(Boolean).join(', ')
}
