<template>
	<div class="row mb pt">
		<div class="col-12">
			<div class="crefo-input">
				<div class="input-group">
					<div class="flex">
						<div class="input-wrapper">
							<input
								id="searchInMessages"
								v-model="searchConfig.einfeldsuche"
								type="text"
								:placeholder="t('sucheInNachrichten')"
								minlength="3"
								data-no-crefo-ui="true"
								data-qa="search-input-field"
								@input="submitSearchForm"
							/>
							<font-awesome-icon
								class="fal fa-sliders-h"
								icon="fa-light fa-sliders"
								data-qa="search-toggle-advanced"
								@click="toggleSearchConfig"
							/>
						</div>
						<button
							class="btn btn-default btn-min"
							data-qa="search-input-btn"
							@click="submitSearchForm"
						>
							<i
								class="crefo-ui-icon icon-magnifier text-bold"
								aria-hidden="true"
							/>
						</button>
					</div>
				</div>
				<div
					v-if="searchConfigActive"
					class="col-12 searchConfig pt"
					data-qa="searchConfigArea"
				>
					<h3>{{ t('erweiterteSuche') }}</h3>
					<div class="row">
						<div class="col-md-6 col-12">
							<div class="crefo-input">
								<div class="input-group">
									<Select
										id="ansicht"
										v-model="searchConfig.ansicht"
										:label="t('searchConfig.ansicht.fieldname')"
										:searchable="false"
										:options="OPTIONS.ANSICHT"
										data-qa="field-ansicht"
									/>
								</div>
							</div>

							<div class="crefo-input">
								<div class="input-group">
									<Select
										id="nachrichtenTyp"
										name="nachrichtenTyp"
										v-model="searchConfig.nachrichtType"
										:label="t('searchConfig.nachrichtType.fieldname')"
										:searchable="false"
										:options="OPTIONS.NACHRICHT_TYPE"
										data-qa="field-nachrichten-typ"
									/>
								</div>
							</div>

							<div class="crefo-input">
								<div class="input-group">
									<Select
										id="nachrichten-typ"
										v-model="searchConfig.leseStatus"
										:label="t('searchConfig.leseStatus.fieldname')"
										:searchable="false"
										:options="OPTIONS.LESE_STATUS"
										data-qa="field-leseStatus"
									/>
								</div>
							</div>
							<div class="crefo-input">
								<div class="input-group">
									<label for="gelesen">{{ t('searchConfig.kategorie.fieldname') }}</label>
									<div
										v-click-outside="closeList"
										class="multi-checkbox-in-combobox"
										@click="showOptions = !showOptions"
									>
										<div
											class="input-wrapper"
											:class="{ 'show-options': showOptions }"
										>
											<div
												class="multi-checkbox-in-combobox-selected"
												:class="{
													'multi-checkbox-in-combobox-arrow-active': showOptions,
												}"
												data-qa="multi-checkbox-in-combobox-selected-text"
											>
												{{ kategorieAuswahlText }}
											</div>
											<div
												class="multi-checkbox-in-combobox-items"
												role="listbox"
												:class="{
													'multi-checkbox-in-combobox-hide': !showOptions,
												}"
											>
												<div
													v-for="category in categoryList"
													:key="category"
													data-qa="field-kategorie-options"
												>
													<input
														:id="category"
														type="checkbox"
														@input="handleCategoryChange"
														:value="category"
														:checked="
															searchConfig.kategorien.includes(category)
														"
														data-qa="field-kategorie-options-input"
													/>
													<label :for="category">
														<font-awesome-icon
															:icon="`fa-solid fa-pennant`"
															:class="`message-search__category-pennant-icon--${category}`"
															data-qa="pennant-picker__list-item__icon__category--color"
														/>
														<span data-qa="field-kategorie-options-text">{{
															t(`Posteingang.PennantPicker.${category}`)
														}}</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="crefo-input">
								<div class="input-group">
									<Select
										id="zeitraum"
										v-model="searchConfig.zeitraum"
										:label="t('searchConfig.zeitraum.fieldname')"
										:searchable="false"
										:options="OPTIONS.ZEITRAUM"
										data-qa="field-zeitraum"
									/>
								</div>
							</div>

							<div
								v-if="searchConfig.zeitraum === 'EIGENER_ZEITRAUM'"
								class="row"
								:class="{ 'has-error': noValidDate }"
								data-qa="fields-vor-and-bis-area"
							>
								<div class="col-6">
									<div class="crefo-input">
										<div class="input-group">
											<label for="searchConfig_zeitraum_von">{{
												t('searchConfig.zeitraumVon.fieldname.VON')
											}}</label>
											<input-date
												id="searchConfig_zeitraum_von"
												v-model:value="searchConfig.vonBis.beginn"
												type="date"
												:placeholder="t('searchConfig.zeitraumVon.types.ZEITRAUM_VON')"
												:disabled-date="notAllowedVon"
												:append-to-body="false"
												data-crefo-ui="false"
											/>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="crefo-input">
										<div class="input-group">
											<label for="searchConfig_zeitraum_bis">{{
												t('searchConfig.zeitraumBis.fieldname.BIS')
											}}</label>
											<input-date
												id="searchConfig_zeitraum_bis"
												v-model:value="searchConfig.vonBis.ende"
												type="date"
												:placeholder="t('searchConfig.zeitraumBis.types.ZEITRAUM_BIS')"
												:disabled-date="notAllowedBis"
												:append-to-body="false"
												data-crefo-ui="false"
											/>
										</div>
									</div>
								</div>
								<div
									v-if="noValidDate"
									class="col-12 error-msg mb-small"
								>
									<!-- eslint-disable vue/no-v-html -->
									<span v-html="t('noValidDateText')" />
									<!-- eslint-enable -->
								</div>
							</div>
						</div>

						<div class="col-md-6 col-12 positioning-bottom-right">
							<div class="filterSaveArea">
								<div class="saveArea mb-small">
									<CrefoTooltip
										v-if="saveAreaTooltipIsVisible"
										:config="{
											placement: 'top',
										}"
										:anker-element="saveTooltipAnker"
									>
										{{ t('searchConfig.saveFilter.tooltip') }}
									</CrefoTooltip>
									<span
										class="saveArea__info-icon"
										@mouseover="saveAreaTooltipIsVisible = true"
										@mouseleave="saveAreaTooltipIsVisible = false"
									>
										<i
											ref="saveTooltipAnker"
											class="crefo-ui-icon icon-circle-info"
											aria-hidden="true"
										/>
									</span>
									<span
										class="btn-icon-save"
										data-qa="filterSaveButton"
										@click="handleSaveFilterClick"
										>{{ t('searchConfig.saveFilter') }}
										<font-awesome-icon icon="fa-light fa-floppy-disk"
									/></span>
								</div>
							</div>

							<div class="buttonArea mb-small">
								<CrefoTooltip
									v-if="resetButtonAreaTooltipIsVisible"
									class="buttonArea__crefo-tooltip"
									:config="{
										placement: 'top',
									}"
									:anker-element="resetTooltipAnker"
								>
									{{ t('searchConfig.reset.tooltip') }}
								</CrefoTooltip>
								<span
									class="buttonArea__info-icon"
									@mouseover="resetButtonAreaTooltipIsVisible = true"
									@mouseleave="resetButtonAreaTooltipIsVisible = false"
								>
									<i
										ref="resetTooltipAnker"
										class="crefo-ui-icon icon-circle-info"
										aria-hidden="true"
									/>
								</span>

								<button
									class="btn btn-default"
									data-qa="filterResetButton"
									@click="resetSearchConfig"
								>
									<span>{{ t('searchConfig.reset') }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<template v-if="selectedFilters.length > 0">
				<div
					class="message-search__tag-input-wrapper"
					v-for="(tag, index) in selectedFilters"
					:key="filterName(tag) + index"
				>
					<TagInput
						data-qa="searchConfig-tag-item"
						@close="removeFilter(tag)"
					>
						{{ filterName(tag) }}
					</TagInput>
				</div>
			</template>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import InputDate from '@/components/atoms/InputDate/index.vue'
import TagInput from '@/components/atoms/TagInput/index.vue'
import CrefoTooltip from '@/components/atoms/CrefoTooltip/index.vue'
import { useI18n } from 'vue-i18n'
import { useMessagesStore } from '@/stores/messages'
import { useUserStore } from '@/stores/user'
import Select from '@/components/atoms/CrefoSelect/index.vue'
import {storeToRefs} from 'pinia'
import {Kategorie} from '@/types/messageBox/MessageSearch'

// needed locale for testing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { t, te, locale } = useI18n()
const messagesStore = useMessagesStore()
const { isFetchingData, pagination, searchConfig } = storeToRefs(messagesStore)
const { dateLocale } = storeToRefs(useUserStore())

const saveTooltipAnker = ref()
const resetTooltipAnker = ref()

const showOptions = ref(false)
const searchConfigActive = ref(false)
const noValidDate = ref(false)
const saveAreaTooltipIsVisible = ref(false)
const resetButtonAreaTooltipIsVisible = ref(false)

const categoryList: Kategorie[] = ['KEINE_KATEGORIE', 'KATEGORIE_1', 'KATEGORIE_2', 'KATEGORIE_3', 'KATEGORIE_4']

const OPTIONS = {
	ANSICHT: [
		{ label: '', code: 'ALLE', i18nKey: 'searchConfig.ansicht.types.ALLE' },
		{
			label: '',
			code: 'MEINE_EIGENEN_NACHRICHTEN',
			i18nKey: 'searchConfig.ansicht.types.MEINE_EIGENEN_NACHRICHTEN',
		},
	],
	NACHRICHT_TYPE: [
		{ label: '', code: 'ALLE_NACHRICHTEN', i18nKey: 'searchConfig.nachrichtType.types.ALLE_NACHRICHTEN' },
		{ label: '', code: 'FIRMENAUSKUENFTE', i18nKey: 'searchConfig.nachrichtType.types.FIRMENAUSKUENFTE' },
		{ label: '', code: 'INKASSO', i18nKey: 'searchConfig.nachrichtType.types.INKASSO' },
		{ label: '', code: 'SIGNALE', i18nKey: 'searchConfig.nachrichtType.types.SIGNALE' },
	],
	LESE_STATUS: [
		{ label: '', code: 'ALLE', i18nKey: 'searchConfig.leseStatus.types.ALLE' },
		{ label: '', code: 'GELESEN', i18nKey: 'searchConfig.leseStatus.types.GELESEN' },
		{ label: '', code: 'UNGELESEN', i18nKey: 'searchConfig.leseStatus.types.UNGELESEN' },
	],
	ZEITRAUM: [
		{ label: '', code: 'ALLE_NACHRICHTEN', i18nKey: 'searchConfig.zeitraum.types.ALLE_NACHRICHTEN' },
		{ label: '', code: 'LETZTEN_30_TAGE', i18nKey: 'searchConfig.zeitraum.types.LETZTEN_30_TAGE' },
		{ label: '', code: 'LETZTEN_60_TAGE', i18nKey: 'searchConfig.zeitraum.types.LETZTEN_60_TAGE' },
		{ label: '', code: 'LETZTEN_90_TAGE', i18nKey: 'searchConfig.zeitraum.types.LETZTEN_90_TAGE' },
		{ label: '', code: 'AKTUELLES_JAHR', i18nKey: 'searchConfig.zeitraum.types.AKTUELLES_JAHR' },
		{ label: '', code: 'VORJAHR', i18nKey: 'searchConfig.zeitraum.types.VORJAHR' },
		{ label: '', code: 'EIGENER_ZEITRAUM', i18nKey: 'searchConfig.zeitraum.types.EIGENER_ZEITRAUM' },
	],
}

const kategorieAuswahlText = computed(() => {
	if (searchConfig.value.kategorien.length > 1) {
		return t('searchConfig.kategorie.types.MEHRERE_KATEGORIEN', {
			anzahl: searchConfig.value.kategorien.length,
		})
	} else if (searchConfig.value.kategorien.length === 1) {
		return t(`Posteingang.PennantPicker.${searchConfig.value.kategorien[0]}`)
	} else {
		return t('searchConfig.kategorie.types.KEINE_KATEGORIE')
	}
})

const selectedFilters = computed(() => {
	let filters: { name: string; value: string | Kategorie}[] = []
	if (searchConfig.value.ansicht !== 'ALLE') {
		filters.push({ name: 'ansicht', value: searchConfig.value.ansicht })
	}
	if (searchConfig.value.nachrichtType !== 'ALLE_NACHRICHTEN') {
		filters.push({ name: 'nachrichtType', value: searchConfig.value.nachrichtType })
	}
	if (searchConfig.value.leseStatus !== 'ALLE') {
		filters.push({ name: 'leseStatus', value: searchConfig.value.leseStatus })
	}
	if (
		searchConfig.value.zeitraum !== 'ALLE_NACHRICHTEN' &&
		searchConfig.value.zeitraum !== 'EIGENER_ZEITRAUM'
	) {
		filters.push({ name: 'zeitraum', value: searchConfig.value.zeitraum })
	}
	if (
		searchConfig.value.zeitraum !== 'ALLE_NACHRICHTEN' &&
		searchConfig.value.zeitraum === 'EIGENER_ZEITRAUM'
	) {
		if (searchConfig.value.vonBis.beginn) {
			filters.push({ name: 'zeitraumVon', value: 'ZEITRAUM_VON' })
		}
		if (searchConfig.value.vonBis.ende) {
			filters.push({ name: 'zeitraumBis', value: 'ZEITRAUM_BIS' })
		}
	}
	if (searchConfig.value.kategorien.length > 0) {
		searchConfig.value.kategorien.map((kategorie) => {
			filters.push({ name: 'kategorien', value: kategorie })
		})
	}
	return filters
})

const filterName = computed(() => {
	return (item: { name: string; value: string }) => {
		dayjs.extend(localizedFormat)
		if (item.name === 'zeitraumVon' || item.name === 'zeitraumBis') {
			let newDate =
				item.value === 'ZEITRAUM_VON'
					? getLocaleFormat(searchConfig.value.vonBis.beginn)
					: getLocaleFormat(searchConfig.value.vonBis.ende)
			return te(`searchConfig.${item.name}.types.${item.value}`)
				? t(`searchConfig.${item.name}.types.${item.value}`) + ' ' + newDate
				: newDate
		} else if (item.name === 'kategorien') {
			return te(`Posteingang.PennantPicker.${item.value}`) ? t(`Posteingang.PennantPicker.${item.value}`) : ''
		} else {
			return te(`searchConfig.${item.name}.types.${item.value}`)
				? t(`searchConfig.${item.name}.types.${item.value}`)
				: ''
		}
	}
})

watch(
	() => searchConfig.value.zeitraum,
	() => {
		if (searchConfig.value.zeitraum === 'EIGENER_ZEITRAUM') return

		searchConfig.value.vonBis.beginn = ''
		searchConfig.value.vonBis.ende = ''
	},
)

watch(
	searchConfig.value,
	async () => {
        await submitSearchForm()
	}
)

function handleCategoryChange(event: any) {
	const indexOfValue = searchConfig.value.kategorien.indexOf(event.target.value)

	if (indexOfValue === -1) {
		searchConfig.value.kategorien.push(event.target.value)
		return
	}

	searchConfig.value.kategorien.splice(indexOfValue, 1)
}

function getLocaleFormat(value: string | Date) {
	return dayjs(value).locale(dateLocale.value.substring(0, 2)).format('L')
}

function notAllowedVon(date: Date) {
	if (searchConfig.value.vonBis.ende) {
		dayjs.extend(isSameOrBefore)
		return !dayjs(date.getTime()).isSameOrBefore(dayjs(searchConfig.value.vonBis.ende))
	} else {
		return Date.now() - date.getTime() < 0
	}
}

function notAllowedBis(date: Date) {
	if (searchConfig.value.vonBis.beginn) {
		dayjs.extend(isSameOrAfter)
		return (
			!dayjs(date.getTime()).isSameOrAfter(dayjs(searchConfig.value.vonBis.beginn)) ||
			Date.now() - date.getTime() < 0
		)
	} else {
		return Date.now() - date.getTime() < 0
	}
}

function removeFilter(filter: { name: string; value?: string | Kategorie}) {
	switch (filter.name) {
		case 'nachrichtType':
			searchConfig.value.nachrichtType = 'ALLE_NACHRICHTEN'
			break
		case 'leseStatus':
			searchConfig.value.leseStatus = 'ALLE'
			break
		case 'zeitraum':
			searchConfig.value.zeitraum = 'ALLE_NACHRICHTEN'
			break
		case 'zeitraumVon':
			searchConfig.value.vonBis.beginn = ''
			break
		case 'zeitraumBis':
			searchConfig.value.vonBis.ende = ''
			break
		case 'ansicht':
			searchConfig.value.ansicht = 'ALLE'
			break
		case 'kategorien':
			searchConfig.value.kategorien = searchConfig.value.kategorien.filter(kategorie => kategorie !== filter.value as Kategorie)
			break
	}
}

function toggleSearchConfig() {
	searchConfigActive.value = !searchConfigActive.value
}

const submitSearchFormDebounced = debounce(async () => {
    pagination.value.pageStartIndex = 0
    await messagesStore.fetchPosteingang()
    isFetchingData.value = false
}, 750 )
async function submitSearchForm() {
    isFetchingData.value = true
    await submitSearchFormDebounced()
}

function handleSaveFilterClick() {
	messagesStore
		.saveSearchFilter()
		.then(() => {
			showSuccessMessage()
		})
		.catch((e) => {
			console.log(e)
			showFailureMessage()
		})
}

async function resetSearchConfig() {
	searchConfig.value.nachrichtType = 'ALLE_NACHRICHTEN'
	searchConfig.value.leseStatus = 'ALLE'
	searchConfig.value.zeitraum = 'ALLE_NACHRICHTEN'
	searchConfig.value.ansicht = 'ALLE'
	searchConfig.value.vonBis.beginn = ''
	searchConfig.value.vonBis.ende = ''
	searchConfig.value.einfeldsuche = ''
	searchConfig.value.kategorien = []
	await submitSearchForm()
}

function showSuccessMessage() {
	window.CrefoUI.Modal.show({
		title: t('MemberStructure.Save.Modal.Success.Title'),
		content: t('MemberStructure.Save.Modal.Success.Content'),
		buttons: {
			confirm: t('MemberStructure.Save.Modal.Success.Btn.Text'),
		},
		icon: 'success',
		dataQa: 'saving-success',
	})
}

function showFailureMessage() {
	window.CrefoUI.Modal.show({
		title: t('MemberStructure.Save.Modal.Failure.Title'),
		content: t('MemberStructure.Save.Modal.Failure.Content'),
		buttons: {
			confirm: t('MemberStructure.Save.Modal.Failure.Btn.Text'),
		},
		icon: 'error',
		dataQa: 'saving-error',
	})
}

function closeList() {
	if (showOptions.value) {
		showOptions.value = false
	}
}

defineExpose({ getLocaleFormat, locale, messagesStore, searchConfigActive, selectedFilters })
</script>


<style lang="scss" scoped>
@import '@/components/molecules/PennantPicker/category-color.scss';
@import '@/assets/scss/functions/px2rem.scss';

.flex {
	display: flex;
}

.searchConfig {
	border: 1px solid $clr-brand-grey;
	border-top: none;

	.saveArea {
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
		padding-bottom: 10px;

		.btn-icon-save:hover {
			color: $clr-brand-blue;
		}

		.btn-icon-save,
		.btn-icon-delete {
			i,
			svg {
				font-size: 18px;
				margin-left: 5px;
			}
		}
	}

	.saveArea,
	.buttonArea {
		display: flex;
		align-items: center;

		&__info-icon {
			cursor: pointer;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $clr-brand-blue;
			margin-right: 10px;
		}
	}
}

.positioning-bottom-right {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
}

.crefo-input .input-group .input-wrapper svg {
	display: block;
	position: absolute;
	top: 51%;
	transform: translateY(-50%);
	right: 1.2rem;
	font-size: 24px;
	cursor: pointer;
}

.crefo-input .input-group .multi-checkbox-in-combobox svg {
	display: inline-flex;
	position: relative;
	top: auto;
	transform: none;
	right: auto;
	font-size: 20px;
	cursor: pointer;
}

.multi-checkbox-in-combobox {
	.input-wrapper {
		&:after {
			content: '\e909';
			font-family: 'crefo-ui-icons' !important;
			font-size: px2rem(24);
			display: block;
			position: absolute;
			background-color: transparent !important;
			color: $clr-brand-grey;
			top: 51%;
			transform: translateY(-50%) rotate(0deg);
			right: 1.2rem;
			width: auto;
			height: auto;
			z-index: 0;
			transition: all 0.15s ease-in-out;
			cursor: pointer;
		}

		&:hover {
			&:after {
				color: $clr-brand-blue;
			}
		}
		&.show-options {
			&:after {
				transform: translateY(-50%) rotate(180deg);
				color: $clr-brand-blue;
			}
		}
	}
}

.multi-checkbox-in-combobox-items div,
.multi-checkbox-in-combobox-selected {
	border: 1px solid transparent;
	cursor: pointer;
	font-size: 15px;
	font-weight: normal;
	line-height: 26px;
	padding: 1rem 1.2rem;
	outline: none;
	color: $clr-gray-3;
}

.multi-checkbox-in-combobox-items {
	position: absolute;
	background-color: $white;
	top: 100%;
	left: -1px;
	right: -1px;
	z-index: 99;
	max-height: 300px;
	overflow-y: auto;
	border: 1px solid $clr-brand-blue;
	border-top: none;
	transition: transform 0.15s ease-in-out;
	transform: scaleY(1);
	transform-origin: top;

	label span {
		margin-left: 10px;
	}
}

.multi-checkbox-in-combobox-arrow-active {
	outline: 1px solid $clr-brand-blue;
}

.multi-checkbox-in-combobox-hide {
	transform: scaleY(0);
}

.message-search {
	&__category-pennant-icon {
		@include category-color;
	}

	&__tag-input-wrapper + &__tag-input-wrapper {
		margin-left: 10px;
	}

	&__tag-input-wrapper {
		display: inline-block;
	}
}
</style>
