<template>
    <div>
        <p>
            Sehr geehrte Damen und Herren,
        </p>

        <p>
            zu Ihrem Produktauftrag {{ templateVars.productOrderId }} über das Unternehmen {{ templateVars.mergedReportData }} werden keine weiteren Nachträge geliefert.
        </p>

        <p>
            <strong>Grund der Beendigung:</strong>
            <ul class="posteingang-inbox__message-details-messages__message__change-list">
                <li
                    v-for="reason in templateVars.signalReasonIdList"
                    :key="reason"
                >
                    {{ t("signalReason." + reason) }}
                </li>
            </ul>
        </p>

        <p>Mit freundlichen Grüßen</p>

        <p>{{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import {useI18n} from "vue-i18n"

const  { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        productOrderId: string
        signalReasonIdList: Array<string>,
        transactionLink: string,
        vcName: string
    }
}>()
</script>
