<template>
    <div>
        <p>
            Mesdames et Messieurs,
        </p>

        <p>
            une notification du rapport commandé {{ templateVars.productOrderId }} sur la société {{
                templateVars.mergedReportData }} est maintenant disponible.
        </p>

        <p>
            <strong>Les changements concernés se présentent comme suit:</strong>
            <ul class="posteingang-inbox__message-details-messages__message__change-list">
                <li v-for="reason in templateVars.supplementReasonIdList" :key="reason">
                    {{ t("supplementReason." + reason) }}
                </li>
            </ul>
        </p>

        <p>
            Veuillez consulter le complément de la notification
            <crefo-message-link :msg-link="templateVars.transactionLink" :msg-text="'ici'" />.
        </p>

        <p>Cordialement</p>

        <p>Votre {{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'
import {useI18n} from "vue-i18n";

const { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        productOrderId: string,
        supplementReasonIdList: Array<string>,
        transactionLink: string,
        vcName:string
    }
}>()
</script>
