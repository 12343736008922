<template>
    <div>
        <p>
            Mesdames et Messieurs,
        </p>

        <p>
            des nouvelles informations sont disponibles dans votre liste de surveillance sur l’entreprise {{
                templateVars.mergedReportData }}.
        </p>

        <p>
            <strong>Les changements concernés se présentent comme suit:</strong>
            <ul class="posteingang-inbox__message-details-messages__message__change-list">
                <li v-for="reason in templateVars.signalReasonIdList" :key="reason">
                    {{ t("signalReason." + reason) }}
                </li>
            </ul>
        </p>

        <p>
            Vous pouvez acheter
            <crefo-message-link :msg-link="templateVars.transactionLink"
                :msg-text="'l’actualisation en cliquant le liens'" />.
        </p>

        <p>Cordialement</p>

        <p>Votre {{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'
import {useI18n} from "vue-i18n";

const { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        signalReasonIdList: Array<string>,
        transactionLink: string,
        vcName: string
    }
}>()
</script>
