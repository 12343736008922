<template>
	<div>
		<p>Sehr geehrte Damen und Herren,</p>

		<p>
			ein neuer Nachtrag zu Ihrem Produktauftrag {{ templateVars.productOrderId }} über
			{{ templateVars.mergedReportData }} liegt jetzt vor.
		</p>

		<div>
			<strong>Die Veränderungen betreffen:</strong>
			<ul class="posteingang-inbox__message-details-messages__message__change-list">
				<li
					v-for="reason in templateVars.supplementReasonIdList"
					:key="reason"
				>
					{{ t('supplementReason.' + reason) }}
				</li>
			</ul>
		</div>

		<p>
			Bitte wechseln Sie hier zur
			<crefo-message-link
				:msg-link="templateVars.transactionLink"
				:msg-text="'Anzeige des Nachtrags'"
			/>.
		</p>

		<p>Mit freundlichen Grüßen</p>

		<p>{{ templateVars.vcName }}</p>
	</div>
</template>

<script lang="ts" setup>
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'
import {useI18n} from "vue-i18n"

const { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        productOrderId: string,
        supplementReasonIdList: Array<string>,
        transactionLink: string,
        vcName: string
    },
}>()
</script>
