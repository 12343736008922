<template>
    <div>
        <p>
            Sehr geehrte Damen und Herren,
        </p>

        <p>
            es liegen neue Informationen zu dem Unternehmen {{ templateVars.mergedReportData }} in Ihrer Watchlist vor.
        </p>

        <p>
            <strong>Die Veränderungen betreffen:</strong>
            <ul class="posteingang-inbox__message-details-messages__message__change-list">
                <li v-for="reason in templateVars.signalReasonIdList" :key="reason">
                    {{ t("signalReason." + reason) }}
                </li>
            </ul>
        </p>

        <p>
            Hier können Sie eine
            <crefo-message-link :msg-link="templateVars.transactionLink"
                :msg-text="'aktuelle Auskunft zu dem Unternehmen'" /> abrufen.
        </p>

        <p>Mit freundlichen Grüßen</p>

        <p>{{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts" setup>
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'
import {useI18n} from "vue-i18n";

const { t } = useI18n()

defineProps<{
    templateVars: {
        mergedReportData: string,
        signalReasonIdList: Array<string>,
        transactionLink: string,
        vcName: string
    }
}>()
</script>
